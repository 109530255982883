import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CustomTextInput } from '../../components/ReduxFormFields';
import { oAuthSignIn } from './actions';
import { Footer, Logo } from '../../components';
import { queryURLParser } from '../../utils';

import i18n from '../../i18n';

import '../../App.css';

/**
 * OAuthSignIn form id
 */
const ROOT_SIGN_IN_FORM = 'rootSignInForm';

const selector = formValueSelector(ROOT_SIGN_IN_FORM);

/**
 * RootSignInForm
 * @memberof module:RootSignIn
 * @description renders Root sign in form
 * @extends Component
 */
const OAuthSignIn = (props) => {
  const { location } = props;
  const dispatch = useDispatch();

  const email = useSelector((state) => selector(state, 'email') || '');
  const password = useSelector((state) => selector(state, 'password') || '');

  const handleSubmit = (event) => {
    event.preventDefault();
    const parsedQuery = queryURLParser(location.search);

    const data = {
      client_id: parsedQuery.client_id,
      response_type: 'code',
      redirect_uri: parsedQuery.redirect_uri,
      scope: parsedQuery.scope,
      login: email,
      password
    };

    dispatch(oAuthSignIn({ data }));
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row align-items-center justify-content-center">
            <div className="col-auto mr-auto">
              <Logo />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-8 col-lg-8 col-md-10 col-sm-10">
                <div className="m-login__head">
                  <h3 className="m-login__title">{i18n.t('headerLogin')}</h3>
                </div>
                <div className="container-fluid">
                  <form
                    className="m-login__form m-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="email"
                        component={CustomTextInput}
                        label={i18n.t('mail')}
                        className="m-input"
                        autoComplete="off"
                        preventAuto
                      />
                    </div>
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="password"
                        component={CustomTextInput}
                        label="pass"
                        className="m-input"
                        type="password"
                        autoComplete="off"
                        preventAuto
                      />
                    </div>
                    <div className="m-login__form-action">
                      <button
                        type="submit"
                        id="m_login_signin_submit"
                        className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary"
                      >
                        {i18n.t('loginbtn')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

OAuthSignIn.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

const form = reduxForm({
  form: ROOT_SIGN_IN_FORM
})(OAuthSignIn);

export default form;
