import React, { useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import i18n from '../../i18n';
import { APP_ID, HELION_APP_ID, ASKOMA_APP_ID, SOLAR_MANAGER_APP_ID, CKW_APP_ID } from '../../config';
import { USER_GET_LICENSE_LIST_URL } from '../../api/apiUrls';
import DataList from '../../containers/DataList';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/multiselectCloseIcon.svg';
import { multiselectConfig } from '../../constants';

const style = {
  ...multiselectConfig,
  multiselectContainer: {
    ...multiselectConfig.multiselectContainer,
    width: '230px',
    maxWidth: undefined
  }
};

export const LicenseFilter = ({
  onChange,
  settings = {},
  classes = ''
}) => {
  const { license } = settings.columns || {};
  const licensesList = useSelector((state) => state.dataList?.licenseFilter?.data) || [];
  const licenseOptions = [{ label: i18n.t('noLicense'), licenseCode: 'null' }, ...licensesList.map((value) => ({ label: value.title, licenseCode: value.id }))];
  const handleLicenseChange = (selectedList) => {
    const licenseCodes = selectedList.map(({ licenseCode }) => licenseCode).join(',') || undefined;
    onChange({ licenseCodes });
  };

  useEffect(() => {
    if (!license && settings.queries?.licenseCodes) {
      onChange({ licenseCodes: undefined });
    }
  }, [license]); // eslint-disable-line

  return license && [APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID, CKW_APP_ID, ASKOMA_APP_ID].includes(APP_ID) ? (
    <>
      <DataList
        listID="licenseFilter"
        listURL={USER_GET_LICENSE_LIST_URL}
        forceKeepData
      />
      <div className="d-flex multi-select-wrapper">
        <div className={`multi-select ${classes}`}>
          <span>{`${i18n.t('License')}:`}</span>
          <Multiselect
            options={licenseOptions}
            placeholder={i18n.t('selectType')}
            showCheckbox
            emptyRecordMsg={i18n.t('noOptionsAvailable')}
            style={style}
            onSelect={handleLicenseChange}
            onRemove={handleLicenseChange}
            displayValue="label"
            selectedValues={licenseOptions.filter((option) => (settings.queries?.licenseCodes || []).includes(option.licenseCode))}
            customCloseIcon={(<CloseIcon />)}
          />
        </div>
      </div>
    </>
  ) : null;
};

LicenseFilter.propTypes = {
  settings: PropTypes.shape({
    queries: PropTypes.shape({ licenseCodes: PropTypes.string }),
    columns: PropTypes.shape({ license: PropTypes.bool })
  }),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.string
};
