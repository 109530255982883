import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListPagination, DataListFilterV2 } from '../DataList';
import { CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import { PrimaryButton } from '../../components/UIKit';
import { AddPvInstallerWindow, DeletePvInstallerWindow } from './modals';
import { Footer, SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';
import { ConfirmDeleteUserWindow } from '../../modals';

import { openModalWindow, modalWindowSend, ADD_DATA_MODAL_ID, REMOVE_DATA_MODAL_ID } from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';

import { getSignInUserOemVisibleWebSelector } from '../../redux-store/selectors/signIn';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import { columnsV2 } from './columns';
import { sendFileRequest } from './actions';
import { USERS_EXPORTS_PV_INSTALLERS_URL, USERS_PV_INSTALLER_USERS_URL, PV_INSTALLER_URL } from '../../api/apiUrls';
import { ReactTableV2 } from '../../components/ReactTableV2';

import i18n from '../../i18n';

import '../Users/index.scss';
import { transformResponse } from '../../utils/transformResponse';

/**
 * @module PvInstallers
 */

const DATA_LIST_ID = 'pv-installers';

/**
 * Renders PV installers page
 * @memberof module:PvInstallers
 */
const PvInstallers = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const visibleWeb = useSelector(getSignInUserOemVisibleWebSelector);
  const { [DATA_LIST_ID]: pageSettings } = useSelector(getPagesSettingsSelector);

  const columnsInstallerV2 = columnsV2(myRoleType, pageSettings);
  const defaultParams = pageSettings.queries;

  const isAdmin = ['root', 'solar_admin'].includes(myRoleType);
  const limitPermissionRoles = ['oem', 'viewer', 'oem_employee', 'oem_employee_read_only'];

  const exportCSV = () => {
    dispatch(sendFileRequest({
      method: 'get',
      url: USERS_EXPORTS_PV_INSTALLERS_URL,
      config: { responseType: 'blob' },
      onResult: { errorMessage: 'exportRequestError', downloadFile: true }
    }));
  };

  const sendAddUser = (fields) => dispatch(modalWindowSend({
    modalID: ADD_DATA_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: USERS_PV_INSTALLER_USERS_URL,
      data: fields
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/../user/${id}`
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  return (
    <div className="main-table-container">
      <AddPvInstallerWindow onSubmit={sendAddUser} />
      <DeletePvInstallerWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('pv_installer').toLowerCase()} />

      <TopBar visibleWeb={visibleWeb} updateButton showNavigation />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('pvInstallers') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div
                className={`col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 ${isAdmin ? '' : 'mr-auto'}`}
              >
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,my_company,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={DATA_LIST_ID}
                  listURL={PV_INSTALLER_URL}
                  transformResponse={transformResponse}
                />
              </div>
              {isAdmin && (
                <div className={`col-4 col-lg-2 order-2 ${isAdmin ? 'mr-auto' : ''}`}>
                  <button
                    type="button"
                    onClick={exportCSV}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('exportInstallers')}
                  </button>
                </div>
              )}
              {!limitPermissionRoles.includes(myRoleType) && (
                <div className="col-auto order-3 btn-sm-us">
                  <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addPVIbtn" isLargeButton customButtonClass="primary" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <ReactTableV2
            listID={DATA_LIST_ID}
            listURL={PV_INSTALLER_URL}
            params={defaultParams}
            transformResponse={transformResponse}
            columns={columnsInstallerV2}
            SubComponent={CompanyInfoSub}
          />
          <DataListPagination
            Component={Pagination}
            listID={DATA_LIST_ID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

PvInstallers.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default PvInstallers;
