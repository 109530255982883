import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const devicesColumns = {
  device_group: 180, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  tag: 180, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  ip: 160, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  mac: 160, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  signal: 170, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  errorcodes: 100, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  sensors_data: 60, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
  buttons: 120, // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards,
  errorDetails: 120 // devices inverters smartMeter 'sub-meter' 'input-devices' loadguards
};

const additionalColumns = {
  plant: false,
  last_login: false,
  installationDate: false,
  installationFinishedDate: false,
  lastStreamUpdate: false,
  sm_id: true,
  oem: false,
  city: false,
  license: false,
  access: false,
  note: false,
  kWp: false
};

const additionalColumnsDevices = {
  ip: true,
  mac: true,
  signal: true,
  errorcodes: true,
  errorDetails: false
};

export const defaultPersistState = {
  dashboard: {
    activeSlide: 0,
    resolution: 1,
    showDevicesCurves: false
  },
  sm_users: {
    queries: {
      _sort: 'sm_id:asc',
      search: '',
      supportContractFlag: undefined,
      isInstallationCompleted: undefined,
      hasErrors: undefined,
      licenseCodes: undefined,
      oems: undefined,
      installers: undefined,
      _limit: 10,
      _start: 0
    },
    columns: additionalColumns,
    width: {
      sm_id: 180,
      last_name: 180,
      signal: 170,
      plant: 160,
      hasErrors: 100,
      license: 160,
      installer: 160,
      oem: 160,
      status: 169, // ?? 169
      last_login: 115,
      installationDate: 165,
      buttons: 120,
      lastStreamUpdate: 100,
      installationFinishedDate: 150,
      city: 120,
      note: 120,
      kWp: 90
    },
    isFilteredTabExpanded: false
  },
  sm_sub_users: {
    queries: {
      search: undefined,
      fields: undefined,
      _sort: 'sm_id:asc',
      _limit: 10,
      _start: 0
    },
    width: {
      sm_id: 180,
      full_name: 180,
      email: 225,
      status: 170 // ?? 169
    }
  },
  monitoring: {
    isFilteredTabExpanded: false,
    queries: {
      _sort: 'lastErrorDate:asc',
      _limit: 10,
      _start: 0,
      search: undefined,
      done: false,
      isInstallationCompleted: undefined,
      supportContractStatus: undefined,
      status: undefined,
      assigned: undefined,
      companies: undefined,
      oems: undefined,
      licenseCodes: undefined
    },
    columns: additionalColumns,
    width: {
      sm_id: 180,
      signal: 70,
      plant: 160,
      name: 140,
      company: 130,
      oem: 160,
      createdAt: 130,
      lastErrorDate: 150,
      assigned: 185,
      priority: 110,
      status: 185,
      buttons: 120,
      installationFinishedDate: 150,
      lastStreamUpdate: 100,
      city: 120,
      license: 160,
      note: 120,
      kWp: 90
    }
  },
  cloudStatusMessage: {
    createdAt: undefined,
    createdAtExtended: undefined
  },
  'pv-installers': {
    queries: {
      _sort: 'company:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      company: 180,
      oems: 180,
      city: 160,
      status: 170,
      last_login: 105,
      buttons: 120
    }
  },
  oems: {
    queries: {
      _sort: 'oem:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      oem: 180,
      city: 160,
      status: 170,
      last_login: 105,
      buttons: 120
    }
  },
  employee: {
    queries: {
      _sort: 'first_name:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      full_name: 180,
      email: 225,
      city: 160,
      status: 170,
      access: 100,
      last_login: 105,
      buttons: 120
    }
  },
  oemEmployee: {
    queries: {
      _sort: 'first_name:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      full_name: 180,
      email: 225,
      city: 160,
      status: 170,
      access: 100,
      last_login: 105,
      buttons: 120
    }
  },
  propMgmts: {
    queries: {
      _sort: 'first_name:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      first_name: 180,
      email: 225,
      phone: 180,
      city: 160,
      status: 170,
      last_login: 105,
      buttons: 120
    }
  },
  viewers: {
    queries: {
      _sort: 'first_name:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      first_name: 180,
      email: 225,
      phone: 180,
      city: 160,
      status: 170,
      last_login: 105,
      buttons: 120
    }
  },
  admins: {
    queries: {
      _sort: 'first_name:asc',
      _limit: 10,
      _start: 0,
      search: undefined
    },
    width: {
      first_name: 180,
      email: 225,
      phone: 180,
      city: 160,
      status: 170,
      last_login: 105,
      buttons: 120
    }
  },
  installed_sm: {
    isFilteredTabExpanded: false,
    queries: {
      _sort: 'sm_id:asc',
      _limit: 10,
      _start: 0,
      search: undefined,
      sm_id_contains: undefined,
      firmware: undefined,
      types: undefined,
      device_names: undefined,
      hasErrors: undefined
    },
    width: {
      sm_id: 165,
      hardware: 165,
      firmware: 165,
      signal: 150,
      hasErrors: 60,
      status: 160,
      company: 160,
      license: 160,
      sendLogBtn: 90,
      rebootReqBtn: 120,
      debug_logging: 90,
      removeDevice: 100,
      removeFirmware: 100,
      releaseGroup: 90,
      initialized: 160
    }
  },
  userDetails: {
    userInfoActiveTab: 0,
    userDevicesActiveTab: 0,
    columns: additionalColumnsDevices,
    width: {
      devices: { ...devicesColumns, priority: 120, type: 180 },
      inverters: devicesColumns,
      'smart-meter': devicesColumns,
      'sub-meter': devicesColumns,
      cars: {
        name: 180,
        signal: 170,
        soc: 160,
        lastUpdate: 160,
        errorcodes: 100,
        buttons: 120,
        remainingDistance: 160
      },
      'input-devices': { ...devicesColumns, description: 180 },
      loadguards: {
        device_group: 180,
        description: 180,
        inputDeviceFunction: 180,
        affectedDevices: 180,
        buttons: 120
      }
    }
  },
  modals: {
    expertModeModalId: true
  },
  productionAnalysisPortfolioSystems: {
    queries: {
      _sort: 'sm_id:asc',
      search: '',
      _limit: 10,
      _start: 0
    },
    columns: {
      kWp: false,
      zip: false,
      supportContractFlag: false,
      totalYieldKWh: false,
      totalPerformanceLossesKWh: false,
      outputRatioPercent: false,
      totalDowntimeLossesKWh: false,
      totalSimulatedYieldKWh: false,
      kWhPerKWp: false,
      performanceLoosRate: false,
      yearSelfConsumptionRate: false,
      yearAutarchy: false
    },
    width: {
      status: 80,
      sm_id: 180,
      last_name: 180,
      installationDate: 165,
      kWp: 150,
      zip: 100,
      supportContractFlag: 150,
      totalYieldKWh: 120,
      totalPerformanceLossesKWh: 150,
      outputRatioPercent: 150,
      totalDowntimeLossesKWh: 150,
      totalSimulatedYieldKWh: 150,
      kWhPerKWp: 150,
      performanceLoosRate: 150,
      yearSelfConsumptionRate: 150,
      yearAutarchy: 150,
      buttons: 120
    }
  },
  productionAnalysisPortfolioStrings: {
    queries: {
      _sort: 'smId:asc',
      search: '',
      _limit: 10,
      _start: 0
    },
    columns: {
      totalPerformanceLossesKWh: false,
      totalDowntimeLossesKWh: false,
      kWhPerKWp: false,
      outputRatioPercent: false,
      zip: false,
      performanceLoosRate: false,
      totalSimulatedYieldKWh: false,
      totalYieldKWh: false
    },
    width: {
      status: 80,
      stringId: 160,
      name: 180,
      installationDate: 165,
      smId: 180,
      capacity: 150,
      tilt: 150,
      orientation: 150,
      totalPerformanceLossesKWh: 150,
      totalDowntimeLossesKWh: 150,
      kWhPerKWp: 150,
      outputRatioPercent: 150,
      zip: 150,
      performanceLoosRate: 150,
      totalSimulatedYieldKWh: 150,
      totalYieldKWh: 150
    }
  },
  performanceAnalysis: {
    queries: {
      _sort: 'name:asc',
      search: '',
      _limit: 100
    },
    columns: {
      totalSimulatedYieldKWh: false,
      totalPerformanceLossesKWh: false,
      outputRatioPercent: false,
      totalDowntimeLossesKWh: false,
      totalYieldKWh: false,
      kWhPerKWp: false,
      performanceLoosRate: false
    },
    width: {
      signal: 80,
      name: 180,
      totalYieldKWh: 120,
      totalPerformanceLossesKWh: 150,
      outputRatioPercent: 150,
      totalDowntimeLossesKWh: 150,
      totalSimulatedYieldKWh: 150,
      kWhPerKWp: 150,
      performanceLoosRate: 150,
      buttons: 120
    }
  },
  generalAnalysis: {
    portfolioActiveTab: 'systems'
  },
  propMgmtDetailsUsers: {
    queries: {
      _sort: 'sm_id:asc',
      search: '',
      _limit: 10,
      _start: 0
    }
  },
  viewerDetailsUsers: {
    queries: {
      _sort: 'sm_id:asc',
      search: '',
      _limit: 10,
      _start: 0
    }
  },
  installerDashboard: {
    queries: {
      _sort: 'totalInstallations:asc',
      search: '',
      _limit: 10,
      _start: 0
    },
    activeTab: 'pv_installer'
  },
  flexDashboard: {
    pvCurve: true,
    chargingCurve: true,
    batteryCurve: true,
    heatPumpCurve: true,
    waterHeatersCurve: true
  }
};

const migrations = {
  1: (state) => ({ ...state, pagesSettings: undefined }),
  32: (state) => ({ ...state, pagesSettings: defaultPersistState }),
  33: (state) => ({ ...state, pagesSettings: defaultPersistState }),
  34: (state) => ({ ...state, pagesSettings: defaultPersistState }),
  35: (state) => ({ ...state, pagesSettings: defaultPersistState }),
  36: (state) => ({ ...state, pagesSettings: defaultPersistState }),
  37: (state) => ({ ...state, pagesSettings: defaultPersistState })
};

export const persistConfig = {
  key: 'root',
  version: 37,
  storage,
  whitelist: ['pagesSettings'],
  migrate: createMigrate(migrations)
};
